import * as React from "react";
import { Link, graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Tags from "../components/tags";

const BlogPostTemplate = ({ data: { previous, next, site, markdownRemark: post }, location }) => {
    const siteTitle = site.siteMetadata?.title || `Title`;
    const image = post.frontmatter?.image || null;
    const tags = post.frontmatter?.tags || [];
    return (
        <Layout location={location} title={siteTitle}>
            <article className="blog-post" itemScope itemType="http://schema.org/Article">
                <header>
                    {image && <img className="post-header-image" src={image} alt="post-header" />}
                    <h1 itemProp="headline">{post.frontmatter.title}</h1>
                    <p className="post-date">{post.frontmatter.date}</p>
                </header>
                <section
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                    className="article-body"
                />

                <Tags tags={tags} />

                <hr className="content-footer-spacer" />
                <footer>
                    <Bio />
                </footer>
            </article>
            <nav className="blog-post-nav">
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    );
};

export const Head = ({ data: { markdownRemark: post } }) => {
    return (
        <Seo
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            image={post.frontmatter.image}
            publishedTime={post.frontmatter.date}
            primaryTag={post.frontmatter?.tags?.length ? post.frontmatter.tags[0] : null}
            isPost={true}
        />
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                tags
                image
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`;
